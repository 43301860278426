import React, { useState } from "react";
import Identifyinginformation from "./Bill-S/Identifying-information";
import Annualreport from "./Bill-S/annual-report";
import { useProSidebar } from "react-pro-sidebar";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { KeyboardArrowUpOutlined } from "@mui/icons-material";
const AccordionItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="cursor-pointe w-full">
      <button
        className="w-full text-left flex mt-4 mb-4 justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>
          <h5 className="text-[14px] font-bold text-gray-500 pt-1">{title}</h5>
        </div>

        <div className="mt-1">
          {" "}
          <span>
            {isOpen ? (
              <KeyboardArrowUpOutlined />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )}
          </span>
        </div>
      </button>
      {isOpen && <div className="">{children}</div>}
    </div>
  );
};
const Social = () => {
  const { collapseSidebar, collapsed } = useProSidebar();
  const [activeStep, setActiveStep] = useState(101);
  const [completedSteps, setCompletedSteps] = useState([]);
  const activeSteps = (id) => {
    setActiveStep(id);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
    setCompletedSteps([...completedSteps, activeStep]);
  };

  // Function to handle "Previous" button click
  const handlePrevious = () => {
    setActiveStep((prevStep) => prevStep - 1);
    // Remove the previous step from completedSteps
    setCompletedSteps((prevCompletedSteps) =>
      prevCompletedSteps.filter((step) => step !== activeStep - 1)
    );
  };

  // Function to check if a step is completed
  const isStepCompleted = (stepNumber) => {
    return completedSteps.includes(stepNumber);
  };



  return (
    <>
      <div className="overflow-x-hidden">
        {/* {showModal && (
          <div className='fixed inset-0 z-50 bg-gray-700 bg-opacity-30 backdrop-blur-sm flex items-center justify-center'>
            <div className='bg-white rounded-lg p-4'>
              <img src={paidsubscription} alt='logo' />
            </div>
          </div>
        )} */}
        <div className="flex">
          <div className="bg-white my-div mt-2 items-start px-3 min-w-[240px] w-[200px] min-h-[85vh] ml-3 socialscreen-scroll">
            <section className="flex h-[100vh] justify-center ">
              <div className="w-80">
                <ul>
                  <AccordionItem title="Bill S-211">
                    <li
                      className="relative flex items-baseline cursor-pointer gap-2 pb-5"
                      onClick={() => activeSteps(101)}
                    >
                      <div
                        className={`${
                          activeStep === 101
                            ? "before:absolute before:left-[5.5px] before:h-full before:w-[1px] before:bg-sky-800  font-bold"
                            : "before:absolute before:left-[5.5px] before:h-full before:w-[1px] before:bg-gray-400"
                        } `}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          className={`${
                            activeStep === 101
                              ? "bi bi-circle-fill fill-sky-800  font-bold "
                              : " bi bi-circle-fill fill-gray-400"
                          } `}
                          viewBox="0 0 16 16"
                        >
                          <circle cx="8" cy="8" r="8" />
                        </svg>
                      </div>

                      <div className="flex items-center">
                        <p
                          className={`${
                            activeStep === 101
                              ? "text-[0.815rem] text-sky-800  font-bold"
                              : " text-[0.815rem] text-gray-600"
                          } `}
                        >
                          Identifying Information
                        </p>
                      </div>
                    </li>
                    <li
                      className="relative flex items-baseline cursor-pointer gap-2 pb-5"
                      onClick={() => activeSteps(102)}
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          className={`${
                            activeStep === 102
                              ? "bi bi-circle-fill fill-sky-800  font-bold "
                              : " bi bi-circle-fill fill-gray-400"
                          } `}
                          viewBox="0 0 16 16"
                        >
                          <circle cx="8" cy="8" r="8" />
                        </svg>
                      </div>

                      <div className="flex items-center">
                        <p
                          className={`${
                            activeStep === 102
                              ? "text-[0.815rem] text-sky-800  font-bold"
                              : " text-[0.815rem] text-gray-600"
                          } `}
                        >
                          Annual report
                        </p>
                      </div>
                    </li>
                  </AccordionItem>
                </ul>
              </div>
            </section>
          </div>
          <div className="w-full ms-3">
            {/* <div className=""> */}
            <div>
              {/* <div className="h-[500px] overflow-scroll"> */}
              {/* Step 1 */}
              {activeStep === 101 && (
                <>
                  <div>
                    <Identifyinginformation activeSteps={activeSteps} />
                  </div>
                </>
              )}
              {activeStep === 102 && (
                <>
                  <div>
                    <Annualreport />
                  </div>
                </>
              )}
              
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Social;
